@import "../../../styles/variable.less";

.card-header {
	border-radius: 10px 10px 0 0;

	&.title-center {
		> .ant-card-head {
			font-size: 16px;
			text-align: center;
		}
	}

	> .ant-card-head {
		font-size: 16px;
		border-radius: 10px 10px 0 0;
		color: @whiteColor;
		background-color: @themeSecondColor;
		font-weight: 600;
	}

	.ant-btn {
		font-size: 16px;
	}

	.ant-btn:hover,
	.ant-btn:focus,
	.ant-btn:active,
	.ant-btn.active {
		color: @whiteColor;
		background-color: @themeColor;
		border-color: @themeColor;
	}
}

.dna-filter {
	[role="treeitem"] {
		margin: 0;
	}

	.ant-select-tree-node-content-wrapper-open,
	.ant-select-tree-node-content-wrapper-close {
		.ant-select-tree-title {
			font-weight: 600;
			font-size: 16px;
		}
	}
}

.stone-detail-tbl {
	width: 100%;
	border-color: #e8e8e8;
	font-size: 14px;
	table-layout: fixed;

	td {
		padding: 5px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&:first-child {
			font-weight: 600;
			max-width: 200px;
		}
	}
}

@img-vid-hw: 400px;
@box-radius: 17px;

.section {
	// border: 1px solid;
	margin-top: 30px;

	&:first-child {
		margin: 0;
	}

	&.stone-detail {
		height: 275px;
	}

	.ant-tabs-tab {
		font-weight: 600 !important;
	}

	.box-container {
		// border: 1px solid red;
		display: flex;
		justify-content: center;

		&.img {
			img {
				max-width: 100%;
				height: auto;
				max-height: calc(@img-vid-hw - 60px);
				border-radius: @box-radius;
			}
		}

		.imageSliderFullBlock {
			height: calc(@img-vid-hw + 80px);
			width: @img-vid-hw !important;

			.slick-slide {
				display: flex;
				justify-content: center;
			}

			iframe {
				width: 100%;
			}

			&.no-msg {
				min-height: unset;
			}
		}

		&.view-cert {
			height: calc(@img-vid-hw + 200px);

			.no-msg {
				align-items: center;

				h4 {
					text-decoration: none;
				}
			}

			iframe {
				width: 100%;
			}
		}

		.img-vid-container {
			width: @img-vid-hw;

			.slick-list {
				z-index: 1;
				background-color: white;
			}

			.slick-arrow {
				height: 50px;
				width: 50px;
			}

			.slick-slide {
				img,
				video {
					border: 1.5px solid @themeColor;
					border-radius: @box-radius;
					height: calc(@img-vid-hw);
					width: 100%;
					object-fit: contain;
				}
			}

			&.naked-eye {
				width: calc(@img-vid-hw - 50px);

				.slick-slide img,
				.slick-slide video {
					width: 100%;
					height: calc(@img-vid-hw + 100px) !important;
				}

				.unavailable {
					width: 100%;
					height: calc(@img-vid-hw + 165px);
				}
			}

			&.diam-images {
				width: @img-vid-hw;

				.unavailable {
					width: 100%;
				}
			}
		}
	}

	.title {
		margin: -12px;
		padding: 12px;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
		color: @themeColor;
		font-weight: 600;
	}
}

.unavailable {
	// border: 1px solid magenta;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	h4 {
		text-decoration: none;
		margin-bottom: 0;
	}
}

@primary-color: #b69865;