@themeColor : #b69865;
@themeSecondColor:#383938;
// @tableBackGroundColor:#eee5dc;
@tableBackGroundColor:#e7e7e7;
// @themeLightColor:#f8f4ef;
@themeLightColor:#e7e7e7;
@whiteColor:#fff;
@tableBorder:#f6f1eb;
@trhover:#f5fbf6;
@trselected:#e9eae9;
@themeDarkBoreder:#000;
@themeGrayColor:#e4e4e4;
@mobilebglight:#fbf8f6;
@headerSearch:#e8d9ca;
@headerStripColor:linear-gradient(to bottom, #ecd180 0%, #7e5d58 165%) !important;
@headerStripColorHover:linear-gradient(to bottom, #9c7b16 0%, #521f17 100%) !important;
@thankBg:linear-gradient(to bottom,#fff 0%,#FFF9F4 100%);
@headerStripBg:#e0bf63;
@inputBorder:#ddcbb9;
@bgLightColor:#e7dacd;
@staticBg:#fafafa;
@notificationColor:linear-gradient(to top, #a37c30, #f8e099);

::-webkit-scrollbar {
    width: 10px;
    height: 5px;
    background-color: #ddd!important;
    -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb {
    background-color: #999 !important;
}
::selection{
    background-color: @themeColor;
}