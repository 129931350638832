.mainHeadingTitle{
    font-size: 18px;
    padding: 0 15px;
    font-weight: 600;
    white-space: break-spaces;
    // white-space: nowrap;
}
.popupInnerTitle{
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 20px 0;
    padding: 0;
    text-decoration: underline;
    line-height: 18px;
}
.FinalClcHKStn {
    color: red;
    font-weight: 600;
    padding-left: 20px;
    animation: blink-animation 1.5s infinite;
}
@keyframes blink-animation {
    from {
        opacity: 0.2;
    }
    to {
        opacity: 1;
    }
}
.innerPageSmallTitle{
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 12px 0;
    padding: 0;
    line-height: 18px;
}
.text-ali-center{
    text-align: center;
}
@primary-color: #b69865;