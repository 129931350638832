.viewdiamond {
  height: 100%;
  
  iframe {
    width: 100%;
    height: 800px;
  }
  .table {
    width: 50%;
    margin: 0 auto;
    .dnaImageTop {
      border-color: #383938;
      border-radius: 0;
    }
    td,
    th {
      border: 1px solid #ddd;
      padding: 6px 10px;
      text-align: center;
    }
    td {
      .viewCerti {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .mainContainer{
    height: auto;    
    .dnaImageSwipe 
      .imageSliderFullBlock {
      text-align: center;
      width: 200%;
      height: 500%;
      display: flex !important;
      // overflow: hidden;
        iframe{
          width: 825px;
          height: 762px;
          overflow: hidden;
        } 
        video{
          margin: auto;
          width: 825px;
          height: 650px;
          object-fit: none;
        }
      }
      .sliderSmallImage {
        padding: 10px 375px;
      }
    }
}

@primary-color: #b69865;