@import "../../../styles/variable.less";

.customCheckBox {
  position: relative;
  label {
    margin-left: 5px;
  }
  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  .customCheckBoxLebel {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    border-collapse: separate;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:after {
      position: absolute;
      top: 50%;
      left: 21%;
      display: table;
      width: 5.71428571px;
      height: 9.14285714px;
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
      -ms-transform: rotate(45deg) scale(0) translate(-50%, -50%);
      transform: rotate(45deg) scale(0) translate(-50%, -50%);
      opacity: 0;
      -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
      transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
      content: " ";
    }
  }
  input:checked ~ .customCheckBoxLebel {
    background-color: @themeColor;
    border: 1px solid @themeColor;
    &:after {
      width: 5px;
      height: 8px;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
    }
  }
}

@primary-color: #b69865;