@import "../../../styles/variable.less";

.customerMaintab {
  display: flex;
  padding: 0 15px;
  margin: 0;
  overflow: hidden;
  border-bottom: 1px solid #efefef;
  position: relative;
  .scrollarea {
    width: 300px;
    .scrollarea-content {
      display: flex;
    }
  }
}
.customerMaintab li,
.customerMaintab .nav-item a {
  list-style: none;
  padding: 11px 8px;
  position: relative;
  text-transform: capitalize;
  font-size: 14px;
  /* letter-spacing: 1.5px; */
  cursor: pointer;
  color: #000;
  white-space: nowrap;
  font-weight: 500;
  margin-bottom: 0;
}
.customerMaintab li.react-tabs__tab--selected:after,
.customerMaintab .nav-item a.active:after {
  content: "";
  width: 40%;
  width: 100%;
  height: 3px;
  background: #000;
  position: absolute;
  bottom: 0;
  border-radius: 0;
  margin: auto;
  left: 0;
  right: 0;
}
.customerMaintab li.react-tabs__tab--selected,
.customerMaintab .nav-item a.active {
  color: #000;
  font-weight: 800;
}
.customerSubTab > ul {
  border-bottom: 1px solid #757575;
  padding: 0 0;
  display: flex;
  margin-bottom: 0;
}
.customerSubTab > ul > li {
  list-style: none;
  margin: 0 20px 0 0;
  padding: 10px 0;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  font-size: 13px;
}
.customerSubTab > ul > li a {
  color: #000;
}
.customerSubTab > ul > li.react-tabs__tab--selected {
  color: #000;
  font-weight: 800;
  border-bottom: 2px solid #000;
}
.customerMaintab .headerInfoIcon {
  width: 12px;
  height: 12px;
  cursor: pointer;
  margin-right: 5px;
}
.leftSubTab {
  ul {
    padding: 0;
    li {
      list-style: none;
      font-size: 15px;
      color: #8690b5;
      padding: 15px 0;
      cursor: pointer;
      &.react-tabs__tab.react-tabs__tab--selected {
        color: @themeColor;
        font-weight: 600;
      }
    }
  }
}

.accountSubTab{
  background: #f5f5f5;
  li{
  &.react-tabs__tab--selected:after{
    content: none;
  }
  }
}

.react-tabs__tab--selected {
  color: #000;
  font-weight: 800;
  border-bottom: 2px solid #000;
  font-size: medium
}

// .accountTabBox .react-tabs__tab--selected-dna {
//   background-color: #b69865;
//   font-weight: 600;
//   width: auto;
//   height: 3em;
//   padding: 5px 10px 10px 10px !important;
//   border-radius: 10px;
//   text-align: center;
//   border: 2px solid #b69865;
// }

// .accountTabBox li {
//   background-color: #ddd;
//   width: auto;
//   height: 3em;
//   padding: 5px 10px 10px 10px !important;
//   border-radius: 10px;
//   text-align: center;
//   border: 2px solid #ddd;
//   font-size: medium
// }
@primary-color: #b69865;