@import "variable.less";

body {
	color: #262828;
}

#root {
	height: 100%;
}

.side-drawer ul li > div {
	width: 100%;
}

.card-table-header a:hover {
	opacity: 0.7;
}

a {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
	&:hover {
		color: @themeColor;
	}
}

* {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

a {
	color: #000;
}

:after,
:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

@font-face {
	font-family: Gilroy;
	src: url("../assets/fonts/Gilroy-Regular.ttf");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: Gilroy;
	src: url("../assets/fonts/Gilroy-Light.ttf");
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: Gilroy;
	src: url("../assets/fonts/Gilroy-Medium.ttf");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: Gilroy;
	src: url("../assets/fonts/Gilroy-SemiBold.ttf");
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: Gilroy;
	src: url("../assets/fonts/Gilroy-ExtraBold.ttf");
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: Gilroy;
	src: url("../assets/fonts/Gilroy-Bold.ttf");
	font-weight: 900;
	font-style: normal;
}

body,
html {
	cursor: default;
	width: 100%;
	scroll-behavior: smooth;
}

body {
	min-height: 100%;
	font-weight: 400;
	color: #999;
	font-size: 14px;
	line-height: 23px;
	letter-spacing: 0.3px;
	font-family: 'Gilroy' !important;
	overflow-x: hidden;
}

h1 {
	font-family: Gilroy;
	font-size: 46px;
	line-height: 55px;
	font-weight: 700;
}

h2 {
	font-family: Gilroy;
	font-size: 24px;
	line-height: 33px;
	letter-spacing: 0.3px;
	font-weight: 700;
}

h3 {
	font-family: Gilroy;
	font-size: 18px;
	line-height: 25px;
	letter-spacing: 0.2px;
	font-weight: 700;
}

h4 {
	font-family: Gilroy;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.2px;
	font-weight: 700;
}

h5 {
	font-family: Gilroy;
	font-size: 14px;
	line-height: 19px;
	letter-spacing: 0.2px;
	font-weight: 700;
}

h6 {
	font-family: Gilroy;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.2px;
	font-weight: 700;
}

.sub-header {
	font-family: Gilroy;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: 0.2px;
	font-weight: 600;
}

label {
	font-family: Gilroy;
	font-size: 14px;
	line-height: 19px;
	letter-spacing: 0.3px;
	font-weight: 600;
}

p {
	font-family: Gilroy;
	font-size: 14px;
	color: #999;
	line-height: 23px;
	letter-spacing: 0.3px;
	font-weight: 400;
}

small {
	font-family: Gilroy;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.2px;
	font-weight: 400;
}

.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

.background-white {
	background: #fff;
}

.width-full {
	width: 100% !important;
}
.width-50 {
	width: 50% !important;
}

.d-block {
	display: block;
	cursor: pointer;
}

.d-flex {
	display: flex !important;
}

.flex-1 {
	flex: 1;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-flow-wrap {
	flex-flow: row wrap;
}

.align-items-center {
	align-items: center;
}

.j-space-between {
	justify-content: space-between;
}
.j-content-center {
	justify-content: center;
}

.text-center {
	text-align: center;
}

.relative {
	position: relative;
	cursor: pointer;
}

.fixed {
	position: fixed;
}

.absolute {
	position: absolute;
}

.o-hidden {
	overflow: hidden;
}

.primary-text {
	// color: @themeColor;
	color: #b69865;
}

.margin-auto {
	margin: auto;
}

.page-heading {
	font-size: 28px;
	line-height: 36px;
	font-weight: 700;
	letter-spacing: 0.4px;
}

.mb-50 {
	margin-bottom: 50px !important;
}

.white-text {
	color: #fff;
}

.mt-30 {
	margin-top: 30px !important;
}

.no-padding {
	padding: 0 !important;
}

.h-full {
	height: 100%;
}

.mb-25 {
	margin-bottom: 25px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance: textfield !important;
}
input {
	&:focus {
		outline: none;
	}
}

.mb-15 {
	margin-bottom: 15px;
}

a {
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

th,
td {
	text-align: left;
	padding: 8px 10px;
	color: #4c4d4f;
}

.slick-slider {
	position: relative;
	display: block;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;
}

.slick-list:focus {
	outline: none;
}

.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}

.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
}

.slick-track:before,
.slick-track:after {
	display: table;
	content: "";
}

.slick-track:after {
	clear: both;
}

.slick-loading .slick-track {
	visibility: hidden;
}

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
}
// .homeBanner1Img {
//   &.rightBlock{
//     .slick-slider{
//       .slick-list{
//         .slick-track {
//           display: flex;
//         }
//       }
//     }
//   }
// }
[dir="rtl"] .slick-slide {
	float: right;
}

.slick-slide img {
	display: block;
}

.slick-slide.slick-loading img {
	display: none;
}

.slick-slide.dragging img {
	pointer-events: none;
}

.slick-initialized .slick-slide {
	display: block;
}

.slick-loading .slick-slide {
	visibility: hidden;
}

.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}

.slick-prev,
.slick-next {
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	width: 20px;
	height: 20px;
	margin-top: -10px;
	padding: 0;
	cursor: pointer;
	color: transparent;
	border: none;
	outline: none;
	background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
	color: transparent;
	outline: none;
	background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
	opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
	opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
	font-family: "slick";
	font-size: 20px;
	line-height: 1;
	opacity: 0.75;
	color: white;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-prev {
	left: -25px;
}

[dir="rtl"] .slick-prev {
	right: -25px;
	left: auto;
}

.slick-prev:before {
	content: "←";
}

[dir="rtl"] .slick-prev:before {
	content: "→";
}

.slick-next {
	right: -25px;
}

[dir="rtl"] .slick-next {
	right: auto;
	left: -25px;
}

.slick-next:before {
	content: "→";
}

[dir="rtl"] .slick-next:before {
	content: "←";
}

// .slick-slider {
//   margin-bottom: 30px;
// }
.slick-dots {
	position: absolute;
	bottom: -45px;
	display: block !important;
	width: 100%;
	padding: 0;
	list-style: none;
	text-align: center;
}

.slick-dots li {
	position: relative;
	display: inline-block;
	width: 10px;
	height: 20px;
	margin: 0 5px;
	padding: 0;
	cursor: pointer;
}

.slick-dots li button {
	font-size: 0;
	line-height: 0;
	display: block;
	width: 6px;
	height: 6px;
	margin: auto;
	padding: 1px;
	cursor: pointer;
	color: transparent;
	border: 0;
	outline: none;
	background: white;
	text-indent: -999999px;
	border-radius: 50%;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
	outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
	opacity: 1;
}

.slick-dots li button:before {
	font-family: "slick";
	font-size: 6px;
	line-height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	content: "•";
	text-align: center;
	opacity: 0.25;
	color: black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
	opacity: 0.75;
	color: black;
}

ul.slick-dots li.slick-active button {
	width: 10px;
	height: 10px;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
th,
td {
	text-align: center;
	padding: 8px 10px;
	color: #4c4d4f;
}

.mr-10 {
	margin-right: 10px;
}

// loader----------

.loader:empty {
	position: absolute;
	top: calc(50% - 4em);
	left: calc(50% - 4em);
	width: 6em;
	height: 6em;
	border: 1.1em solid rgba(0, 0, 0, 0.2);
	border-left: 1.1em solid @themeColor;
	border-radius: 50%;
	animation: load8 1.1s infinite linear;
}

@keyframes load8 {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

// loader end--------

.avtar-default {
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 160px;
	height: 160px;
	background: rgba(153, 153, 153, 0.1);
}

.avtar {
	border-radius: 50%;
	overflow: hidden;
}

.avtar-xl {
	width: 160px;
	height: 160px;
}

.avtar-fs {
	width: 80px;
	height: 80px;
}

.avtar-sm {
	width: 42px;
	height: 42px;
}

.avtar img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.mb-30 {
	margin-bottom: 30px;
}
th,
td {
	text-align: left;
	padding: 8px 10px;
	color: #4c4d4f;
}

.mb-0 {
	margin-bottom: 0 !important;
}
.ant-select {
	border: 1px solid rgba(153, 153, 153, 0.5);
	border-radius: 3px;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.ant-select:hover,
.ant-select:hover:focus {
	outline: none;
	border: 1px solid @themeColor;
	box-shadow: 0 4px 5px rgba(40, 56, 149, 0.1);
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
	background-color: @themeLightColor;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple
	.ant-select-dropdown-menu-item-selected:hover
	.ant-select-selected-icon {
	color: @themeColor;
}
.mt-5 {
	margin-top: 5px;
}
.pd0 {
	padding: 0px !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.ml-5 {
	margin-left: 5px;
}
.mr-5 {
	margin-right: 5px;
}
.align-arrow {
	display: flex;
	justify-content: center;
}
.mb-90 {
	margin-bottom: 90px !important;
}

.ml-0 {
	margin-left: 0 !important;
}

.mr-0 {
	margin-right: 0 !important;
}

.m-0 {
	margin: 0 !important;
}

.mt-20 {
	margin-top: 20px !important;
}

.hidden {
	display: none;
}

.ant-modal-footer button {
	font-size: 13px;
	padding: 4px 22px !important;
}

.ant-calendar-selected-date .ant-calendar-date {
	color: #57968f !important;
	background: #bae7ff !important;
	border-color: #57968f !important;
}

.width-auto {
	width: auto;
}

.ant-radio-inner::after {
	background-color: @themeColor !important;
}

.ant-radio-checked .ant-radio-inner {
	border-color: @themeColor !important;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
	border-color: #000 !important;
}
.ant-radio-input:focus + .ant-radio-inner {
	box-shadow: 0 0 0 3px rgba(192, 158, 124, 0.08) !important;
	border-color: @themeColor !important;
}
.justify-center {
	justify-content: center;
}

.mb-10 {
	margin-bottom: 10px;
}
.mb-20 {
	margin-bottom: 20px;
}

.width-49 {
	width: 49%;
}

.height-100 {
	height: 100%;
}

.no-data-found {
	width: 100%;
	height: 100%;
	object-fit: contain;
	filter: grayscale(1);
}

.mr-20 {
	margin-right: 20px !important;
}

.width15 {
	width: 15% !important;
}

.width25 {
	width: 25% !important;
}

.width20 {
	width: 20% !important;
}

.justify-end-flex {
	justify-content: flex-end !important;
}

.width-auto {
	width: auto !important;
}

#loader.APILoader {
	display: none;
}
#loader.APILoader.loading {
	display: block;
}
// new css
.commonButton {
	// background-color: @themeColor;
	background-color: #b69865;
	margin: 3px;
	// border: 1px solid @themeColor;
	border: 1px solid #b69865;
	padding: 8px 14px;
	border-radius: 5px;
	color: #fff;
	font-weight: 600;
	font-size: 13px;
	letter-spacing: 0.5px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	&:hover {
		color: #b69865;
		background-color: #fff;
	}
	&:focus {
		outline: none;
	}
	&.smallButton {
		padding: 3px 8px;
		font-size: 13px;
	}
}
.commonOutline {
	background-color: #fff;
	margin: 3px;
	border: 1px solid @themeColor;
	padding: 6px 10px;
	border-radius: 5px;
	color: #fff;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 0.5px;
	color: @themeColor;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	&:hover {
		color: #fff;

		background-color: #b69865;
		// background-color: @themeColor;
		border: 1px solid #b69865;
	}
	&:focus {
		outline: none;
	}
	&.smallButton {
		padding: 4px 10px;
	}
}

.p-0 {
	padding: 0 !important;
}
.justify-space-between {
	justify-content: space-between;
}

.actionButton {
	display: flex;
	justify-content: center;
	.actionAction {
		width: 25px;
		height: 25px;
		margin: 0 2px;
		display: flex;
		border-radius: 100%;
		background-color: @themeLightColor;
		img {
			width: 14px;
			height: 14px;
			margin: auto;
		}
	}
}
.activeStatus span {
	padding: 5px 13px;
	background: #ecfdf3;
	border-radius: 8px;
	color: #429945;
	font-size: 12px;
	text-transform: uppercase;
}
.activeStatus.red span {
	background: #fdecf1;
	color: #f5222dd4;
}
.stausButton {
	padding: 5px 13px;
	background: #ecfdf3;
	border-radius: 8px;
	color: #429945;
	font-size: 12px;
	text-transform: uppercase;
	&.active {
		color: #429945;
		background: #ecfdf3;
	}
	&.sold {
		background-color: #d8e9ff;
		color: #096bef;
	}
	&.pending {
		background-color: #ffe5fa;
		color: #ea81dd;
	}
	&.return {
		background-color: #feface;
		color: #d8c700;
	}
}
.commonLabel {
	color: #000;
	font-size: 14px;
	display: block;
	width: 100%;
	font-weight: 600;
}
.dotList {
	ol {
		margin-left: 15px;
	}
	li {
		margin-bottom: 0 !important;
		position: relative;
		font-weight: 500;
		padding-bottom: 0;
		margin-top: 0;
		color: #555;
		&:after {
			width: 4px;
			height: 4px;
			left: 0;
			background-color: #555;
			top: 16px;
			position: absolute;
			border-radius: 100%;
		}
	}
	p {
		margin-bottom: 0 !important;
		position: relative;
		padding-left: 15px;
		font-weight: 500;
		padding-bottom: 0;
		margin-top: 0;
		&:after {
			content: "";
			width: 4px;
			height: 4px;
			left: 0;
			background-color: #555;
			top: 16px;
			position: absolute;
			border-radius: 100%;
		}
	}
	.button-container {
		display: flex;
		justify-content: center;
		margin-top: 20px;
	}
}
.bottom-0 {
	bottom: 0 !important;
}

.mr-10 {
	margin-right: 10px;
}
.underline {
	text-decoration: underline;
}

// Reminder
.reminder-block {
	width: calc(50% - 20px);
	text-align: center;
	margin-bottom: 40px;
	background: #fafafa;
	margin: 10px;
	padding: 20px;
	border: 1px solid #ddd;
	border-radius: 10px;
}
.reminder-block.active {
	border: 1px solid @themeColor;
	background-color: @themeLightColor;
}
.reminderImageBlock {
	margin-right: 10px;
}
.reminderDetail {
	text-align: left;
}

.reminder-block img {
	width: 30px;
	margin-bottom: 10px;
}

.reminder-datepicker span.modal-title {
	width: 100%;
	color: #5e5e5e;
	font-size: 12px;
	font-weight: 600;
}

.reminder-datepicker {
	width: 100%;
}

.reminder-block h3 {
	font-size: 16px;
	margin-bottom: 5px;
}

.reminder-block span {
	color: #454545;
}

.reminder-wrapper {
	width: auto;
	display: flex;
	border-radius: 5px;
	min-width: 160px;
	cursor: pointer;
}

.position-relative {
	position: relative !important;
}
.ant-tooltip-content .ant-tooltip-inner {
	width: max-content;
}
.p-lr-15 {
	padding: 0 15px;
}
i.ant-spin-dot-item {
	background-color: @themeColor !important;
}
.mt-50 {
	margin-top: 50px;
}
.justify-content-between {
	justify-content: space-between;
}
.mt-10 {
	margin-top: 10px;
}
.mr-40 {
	margin-right: 40px !important;
}
.ant-calendar-selected-date .ant-calendar-date {
	background: #fafafa !important;
	border: 1px solid #000 !important;
	color: #000 !important;
}
.deleteMessage {
	font-size: 16px;
}
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover {
	background-color: @themeColor;
}
.ant-calendar-range .ant-calendar-in-range-cell::before,
.ant-calendar-date:hover {
	background: @themeLightColor;
}
.ant-checkbox-checked .ant-checkbox-inner {
	background-color: @themeColor;
	border-color: @themeColor;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: @themeColor;
}
.p-10 {
	padding: 10px;
}
.height-auto {
	height: auto !important;
}
.themeColor {
	color: @themeColor;
	font-weight: 600;
}
.viewLink {
	text-decoration: underline;
	color: #000;
}
.ant-select-open {
	.ant-select-selection {
		border: none;
		&:focus {
			outline: none !important;
			box-shadow: none !important;
			border: 1px solid #c09e7c;
		}
	}
}
@media (max-width: 767px) {
	.m-width-100 {
		width: 100% !important;
	}
}
.accountDropdown {
	padding: 10px;
}
.accountDropdown .ant-select {
	border: none;
	box-shadow: none !important;
	margin-right: 10px;
}
.accountDropdown button img {
	width: 13px;
	height: 13px;
}
.accountDropdown .inputBox {
	border: 1px solid #e4e4e4;
	padding: 4px 10px;
	border-radius: 5px;
	margin-right: 10px;
}
.accountDropdown .ant-select-selection {
	border: 1px solid #e4e4e4;
	box-shadow: none !important;
}
.ant-select-dropdown {
	min-width: 80px !important;
	z-index: 100000 !important;
}
.listTag {
	padding: 10px;
}
.listTag .listTagList {
	display: flex;
	align-items: center;
	margin: 2px;
}
.listTag .listTagList span.listLabel {
	color: #000;
	font-weight: bold;
	margin-right: 5px;
	display: block;
}
.pointer-events-none {
	pointer-events: none !important;
}
.termsPopup {
	z-index: 100000001 !important;
}

.ant-btn {
	color: @whiteColor;
	background-color: @themeColor;
	border-color: @themeColor;
	font-weight: 600;

	.icon {
		width: 16px;
		height: 16px;
	}
}

@primary-color: #b69865;