  @import "../../styles/variable.less";
  
  .top-arrow {
    position: fixed;
    z-index: 1;
    right: 42px;
    bottom: 125px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.19);
    background-color: #fff;
    cursor: pointer;
    img {
      width: 30px;
      height: 30px;
      margin: auto;
      object-fit: contain;
    }
  }
  .qrCodeOption {
    position: fixed;
    z-index: 1;
    right: 20px;
    bottom: 18px;
    width: 90px;
    padding: 12px 0 10px 0;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.19);
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    img,
    canvas {
      width: 50px;
      height: 50px;
      margin: auto;
      object-fit: contain;
    }
    span {
      color: #000;
      text-transform: uppercase;
      font-size: 11px;
      text-align: center;
      display: block;
      margin-top: 5px;
      width: 100%;
      line-height: 16px;
      font-weight: 600;
    }
  }
  .dnaContainerBox {
    padding: 0px 15px 0 15px;
    max-width: 1140px;
    margin: auto;
  }
  .dnaWrapperBox {
    &:first-child {
      width: 100%;
    }
    width: 100%;
    border: 1px solid #ddd;
    margin: 10px;
    border-radius: 10px;
    &.threeBlock1 {
      width: 50%;
      height: 40em;
    }
    &.threeBlock2 {
      width: 50%;
      height: 37em;
    }
  }
  .dnaImageSwipe {
    text-align: -webkit-center;
    .slick-track {
      margin: auto;
    }
    .sliderMainImage {
      display: flex;
      align-items: center;
    }
    .imageSliderFullBlock {
      text-align: center;
      width: 32em !important;
      height: 35em;
      display: flex !important;
      
      iframe{
        border: none;
        width: 100%;
        height: 100%;
        margin: auto; 
      }
      img {
        border: none;
        width: 100%;
        height: 100%;
        margin: auto;
        object-fit: contain;
      }
      .magnifier {
        width: 100%;
        margin: 0 auto auto auto;
        img {
          // width: 400px;
          width: 100%;
          border-radius: 17px;
        }
      }
      &.width-100 {
        iframe {
          width: 100%;
          height: 650px;
        }
      }
    }
    .imageSliderSmallBox {
      position: relative;
      height: 80px;
      cursor: pointer;
    }
    .imageSliderSmallBlock {
      padding-right: 5px;
      img,
      iframe {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    .imageVideoIcon {
      position: absolute;
      bottom: 3px;
      right: 3px;
      text-align: right;
      background: #000000;
      border-radius: 3px;
      padding: 6px;
      line-height: 1;
      font-size: 12px;
      color: #ffffff;
      display: flex;
      img {
        width: 12px;
        height: 12px;
      }
    }
    .imageVideoTitle {
      font-size: 13px;
      display: block;
      margin-top: 5px;
      color: #454545;
      white-space: pre-wrap;
      text-align: center;
    }
    .sliderSmallImage {
      padding: 10px 24px;
      .slick-slide {
        // width: 90px !important;
        height: auto;
        &.slick-current {
          .imageSliderSmallBlock {
            .imageSliderSmallBox {
              border: 2px solid @themeColor;
              padding: 2px;
            }
            .imageVideoTitle {
              font-weight: 600;
              color: #000;
            }
          }
        }
      }
    }
  }
  .threeBlock1 {
    .sliderSmallImage {
      .slick-slide {
        &.slick-cloned {
          display: none;
        }
      }
    }
  }
  .dnaLeftBox {
    .dnaLeftBoxImage {
      border-right: 1px solid #ddd;
      
    }
  }
  .dnaLeftBoxImage {
    width: 100%;
    .imageSliderFullBlock {
      height: 55vh !important;
      width: 555px !important;
      margin: auto;
      .magnifier {
        height: 100% !important;
      }
      img {
        width: 100% !important;
        height: 100%;
      }
    }
  }
  .dnaImageTop {
    padding: 11px;
    font-size: 15px;
    background-color: @themeSecondColor;
    border-bottom: 1px solid #ddd;
    border-radius: 10px 10px 0 0;
    margin: 0 0 10px 0;
    span {
      display: block;
      font-weight: 600;
      color: #fff;
      text-align: center;
      min-height: 22px;
    }
    &.mainBox {
      background: @themeSecondColor;
      margin: 0 0 10px 0;
      border: none;
      top: 0;
      z-index: 10;
      width: 100%;
      span {
        color: #fff;
      }
    }
  }
  .dnaLeftRightDetil {    // float: left;

    width: 100%;
    margin-bottom: 20px;
    position: relative;
    .dnaDetailBlock {
      width: 33.33%;
      border-right: 1px solid #ddd;
      padding: 0 10px;
      align-items: flex-start;
      .mainHeadingTitle {
        background: #f5f5f5;
        width: 100%;
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 16px;
        padding: 10px;
        display: flex;
        align-items: center;
      }
    }
  }
  .dnaDetailBlock {
    padding: 10px;
    .detail {
      width: 100%;
      display: flex;
      justify-content: center;
      span {
        color: #000;
        display: block;
        border: 1px solid #ddd;
        padding: 3px 8px;
        border-bottom: none;
        &:first-child {
          width: 161px;
          font-size: 14px;
          letter-spacing: 0.5px;
        }
        &:last-child {
          font-size: 14px;
          font-weight: 500;
          word-break: break-word;
          width: calc(53%);
        }
        &:nth-child(odd) {
          border-right: none;
        }
      }
      &:last-child {
        span {
          border-bottom: 1px solid #ddd;
        }
      }
      &.width-100 {
        span {
          width: auto;
        }
      }
    }
  }
  .dnaJourenySticky {
    position: sticky;
    top: 0;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 20px;
    &.video-m{
      video{
        height: auto;
      }
    }
  }
  .diamondJourneyBlock {
    display: flex;
    justify-content: space-between;
    align-items: normal;
    flex-wrap: wrap;
    padding: 10px;
    .diamondJourneyItem {
      margin-bottom: 20px;
      width: calc(24%);
      margin: 5px;
      height: auto;
      text-align: center;
      border: 1px solid #ddd;
      padding: 10px;
      border-radius: 10px;
      .diamondJourneyTitle {
        margin-bottom: 10px;
        span {
          background-color: #fafafa;
          color: #000;
          font-weight: 600;
          font-size: 16px;
        }
      }
      .diamondJourneyImage {
        margin: auto;
        width: 100%;
        height: 200px;
        border-radius: 10px;
        overflow: hidden;
        .no-msg {
          min-height: 200px !important;
        }
        .magnifier {
          height: 100% !important;
        }
        img {
          border-radius: 10px;
          width: 100%;
          height: 100%;
          border: none;
          object-fit: contain;
        }
        iframe {
          box-sizing: border-box;
          width: 9vw;
          height: 15.25vw;
          /* overflow: hidden; */
          border: none;
        }
      }
    }
  }
  .dnaDetailShareOption {
    display: flex;
    position: absolute;
    right: 5px;
    top: 4px;
  }
  .dnaShareOption {
    width: auto;
    height: 35px;
    background-color: @themeColor;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;
    margin: 0 2px;
    img {
      margin: auto;
      width: 16px;
      height: 16px;
    }
    span {
      margin-left: 10px;
      // color:@themeColor !important;
    }
  }
  .dnaWrapperBox .slick-prev,
  .dnaWrapperBox .slick-next {
    width: 34px;
    height: 34px;
    border: 1px solid #ddd;
    border-radius: 100%;
    left: 15px;
    cursor: pointer;
    z-index: 1;
    background-color: #fff !important;
  }
  .dnaWrapperBox .slick-next {
    left: auto;
    right: 15px;
  }
  .dnaWrapperBox .sliderSmallImage .slick-prev,
  .dnaWrapperBox .sliderSmallImage .slick-next {
    top: 50px;
    text-align: center;
  }
  .dnaWrapperBox .slick-prev::before,
  .dnaWrapperBox .slick-next::before {
    content: "";
    background-image: url("../../assets/svg/DNA/left-arrow.svg");
    width: 14px;
    height: 14px;
    background-size: 100%;
    opacity: 1;
    display: block;
  }
  .dnaWrapperBox .slick-prev::before {
    margin-left: 8px;
    margin-right: auto;
  }
  .dnaWrapperBox .slick-next::before {
    margin-right: 8px;
    margin-left: auto;
  }
  .dnaWrapperBox .slick-next::before {
    transform: rotate(180deg);
  }
  .dnaWrapperBox .slick-prev:hover {
    background-color: #fff;
  }
  .similarStoneScroll {
    height: 670px;
    overflow: auto;
  }
  .similarStoneBlock {
    .similarStoneBlockItem {
      width: 100%;
      padding: 10px;
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
      span {
        display: block;
        text-align: center;
        margin-top: 10px;
        color: #000;
      }
    }
  }
  .no-msg {
    min-height: 540px;
    width: 100%;
    font-size: 18px;
    color: #000;
    text-align: center;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }
  @media screen and (min-width : 1640px) and (max-width : 2000px){
    .diamondJourneyBlock {
      .diamondJourneyItem {
        .diamondJourneyImage {
          iframe{
            width: 8vw;
            height: 12.25vw;
          }
        }
      }
    }
  }
  @media screen and (min-width : 1300px) and (max-width : 1450px){
    .diamondJourneyBlock {
      .diamondJourneyItem {
        .diamondJourneyImage {
          iframe{
            width: 11vw;
            height: 14.25vw;
          }
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .dnaContainerBox {
      padding: 0px 15px 0 15px;
      .dnaWrapper {
        &.width-100{
          .dnaWrapperBox{
            height: 590px;
          }
        }
        .dnaWrapperBox {
          margin: 5px 5px;
          &.threeBlock1 {
            width: 36%;
          }
          &.threeBlock2 {
            width: 28%;
          }
        }
        .dnaImageSwipe {
          .imageSliderFullBlock {
            height: 300px;
            .magnifier {
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .similarStoneScroll {
      height: 540px;
    }
    .dnaJourenySticky{
      iframe{
        height: 450px !important;
      }
    }
  }
  @media  (max-width: 768px)  {
    .dnaImageTop{
      &.mainBox{
        &.d-flex{
          display: flex;
          justify-content: space-between;
        }
      }
    }
    // .dnaJourenySticky{
    //   iframe{
    //     height: 600px !important;
    //   }
    // }
  }

  @media (max-width: 767px) {
    .dnaImageSwipe {
      // height: 650px;
      // height: 400px;
      .dnaLeftBox {
        &.dnaLeftBox-m-h{
          height: 550px;

        }
      }
      .slick-track{
        height: 470px;
      }
    }
    .dnaImageSwipe {
      &.video{
        height: 650px;
        // height: 400px;
        .slick-track{
          height: 470px;
        }
      }
    }
    .dnaImageSwipe {
      &.image{
        height: 530px;
      // height: 400px;
        .slick-track{
          height: 330px;
        }
      }
    }
    .dnaContainerBox {
      padding: 0px 15px 0 15px;
      // padding: 25px 15px 0 15px;
      // padding: 90px 15px 0 15px;
      .dnaWrapper {
        flex-wrap: wrap;
        .dnaWrapperBox {
          width: 100%;
          margin: 10px 0;
        }
        .dnaImageSwipe {
          // .viewdiamond {
          //   .videos {
          //     .img-div {
          //       height: auto;

          //       .box-shadow{
          //         height: auto;
          //       }
          //     }
          //   }
          // }
          .imageSliderFullBlock {
            .magnifier {
              img {
                width: 100%;
              }
            }
          }
          .sliderTwoImageBlock {
            .imageSliderFullBlock {
              height: auto;
            }
          }
        }
      }
      .similarStoneScroll {
        height: 470px;
        .no-msg {
          min-height: 470px;
        }
      }
      .dnaWrapperBox {
        margin: 0;
      }
      .dnaLeftRightDetil {
        .dnaDetailBlock {
          width: 100%;
          margin-bottom: 15px;
          border-right: 0;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .dnaLeftBoxImage {
        &.diamond-Image{
          .slick-track{
            height: 370px;
          }
        }
        .imageSliderFullBlock {
          width: 100% !important;
          height: 100% !important;
          img {
            width: 100% !important;
            height: 100% !important;
            // height: auto !important;
          }
        }
      }
      .diamondJourneyBlock {
        flex-wrap: wrap;
        .diamondJourneyItem {
          width: 100%;
        }
      }
      .dnaJourenySticky {
        iframe {
          height: 360px !important;
        }
      }
    }
    .dnaImageTop {
      &.mainBox {
        text-align: center;
      }
      .dnaDetailShareOption {
        position: relative;
        margin: auto;
        right: 0;
        left: 0;
        width: max-content;
      }
    }
    .qrCodeOption {
      display: none;
    }
  }
  .viewImageBlock {
    align-items: flex-start;
    .viewImageBlockLeft {
      width: 25%;
      padding: 10px;
      .dnaWrapperBox {
        margin: 0;
      }
      p {
        padding: 0 10px;
        color: #000;
        font-weight: 600;
      }
    }
    &.centeBlock {
      .viewImageBlockLeft {
        width: 30%;
      }
    }
    .viewImageBlockRight {
      width: calc(37.5% - 20px);
      display: flex;
      margin: 10px;
      justify-content: center;
      align-items: flex-start;
      border: 1px solid #ddd;
      border-radius: 10px;
      padding: 10px;
      .magnifier {
        text-align: center;
        max-width: 600px;
        max-height: 600px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: auto;
      }
      &.halfBlock {
        width: 40%;
      }
      &.PlottingImages {
        .magnifier {
          max-width: 750px;
          max-height: 500px;
        }
        img {
          object-fit: contain;
        }
      }
      &.HeartImages,
      &.ArrowImages {
        justify-content: center;
        .magnifier {
          max-width: 500px;
          max-height: 500px;
        }
      }
    }
  }
  .viewImageDetail {
    width: 100%;
    display: flex;
    padding: 0 10px;
    margin: 20px auto auto auto;
    span {
      width: 60px;
      color: #000;
      font-weight: 600;
    }
    p {
      width: calc(100% - 60px);
    }
    .dnaJourenySticky{
        iframe{
          height: 500px !important;
        }
      }
  }

  @media (max-width: 767px) {
    .viewImageBlock {
      flex-wrap: wrap;
      .viewImageBlockLeft {
        width: 100% !important;
      }
      .viewImageBlockRight {
        width: 100% !important;
      }
    }
  }

  .slick-list {
    width: 100% !important;
  }
@primary-color: #b69865;