.viewdiamond {

  .videos {
    padding-top: 3%;
    padding-bottom: 20px;
    width: 100%;
    margin: auto;
    text-align: center;

    .row {
      border: 8px solid #dbbe77;
      display: flex;
      padding: 15px;
      justify-content: space-around;
      height: 50em;
    }

    .video-div {
      text-align: center;
      height: 574px;
      width: 24%;
      .box-shadow {
        width: 100%;
        height: 574px;
        border: 1px solid gray;
        justify-content: center;
        align-items: center;
        display: flex;
      }
      video {
        width: 100%;
        height: 100%;
      }
    }
    .img-div {
      text-align: center;
      height: 574px;
      width: 24%;
      .box-shadow {
        width: 100%;
        height: 574px;
        border: 1px solid gray;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .seprator {
      width: 10px;
      background-color: white;
    }
  }

  .videos1 {
    padding: 10px;
    width: 100%;
    margin: auto;
    text-align: center;
    .rows {
      border: 2px solid #ddd;
      display: flex;
      padding: 15px;
      justify-content: space-around;
      border-radius: 10px;
      height: 48em;
    }

    .video-div {
      text-align: center;
      height: 574px;
      width: 24%;

      .box-shadow {
        width: 100%;
        height: 574px;
        border: 1px solid gray;
        justify-content: center;
        align-items: center;
        display: flex;
      }
      video {
        width: 100%;
        height: 100%;
      }
    }
    .img-div {
      text-align: center;
      height: 574px;
      width: 24%;

      .box-shadow {
        width: 100%;
        height: 574px;
        border: 1px solid gray;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .seprator {
      width: 10px;
      background-color: white;
    }
  }

  .table {
    width: 50%;
    margin: 0 auto;
    .dnaImageTop {
      border-color: #383938;
      border-radius: 0;
    }
    td,
    th {
      border: 1px solid #ddd;
      padding: 6px 10px;
      text-align: center;
    }
    td {
      .viewCerti {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
.viewdiamond .magnifying-glass{
  background-size: 400% !important;
}

@media only screen and (min-width: 390px) and (max-width: 726px) {
  .viewdiamond {
    .videos {
      .row {
        border: 8px solid #dbbe77;
        display: block;
        padding: 15px;
        justify-content: space-around;
        height: 85em;
      }

      .video-div {
        width: 70%;
        margin: auto;

        .box-shadow {
          width: 100%;
          height: 574px;
          border: 1px solid gray;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        video {
          width: 100%;
          height: 100%;
        }
      }
      .img-div {
        width: 70%;
        margin: auto;
        .box-shadow {
          width: 100%;
          height: 574px;
          border: 1px solid gray;
          justify-content: center;
          align-items: center;
          display: flex;
        }
  
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  
    .videos1 {
      .rows {
        border: 1px solid lightgray;
        display: block;
        padding: 15px;
        justify-content: space-around;
        height: 80em;
      }
      .video-div {
        width: 70%;
        height: 20em;
        margin: auto;
        .box-shadow {
          width: 100%;
          height: 15em;
          border: 1px solid gray;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        video {
          width: 100%;
          height: 100%;
        }
      }
      .img-div {
        width: 70%;
        height: 20em;
        margin: auto;
        .box-shadow {
          width: 100%;
          height: 15em;
          justify-content: center;
          align-items: center;
          display: flex;
        }
  
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  
    .table {
      width: 50%;
      margin: 0 auto;
      .dnaImageTop {
        border-color: #383938;
        border-radius: 0;
      }
      td,
      th {
        border: 1px solid #ddd;
        padding: 6px 10px;
        text-align: center;
      }
      td {
        .viewCerti {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

@primary-color: #b69865;