@import "../styles/variable.less";

.main-screen{
  background-color: #000;
}
.text-left {
  text-align: left !important;
}
.loginBasicList {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  background-color: #fff;
  &::before {
    content: '';
    width: 100%;
    height: 3px;
    // background-image: url(http://fndemo.democ.in/static/media/header-bg.d043886c.svg);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    li {
      list-style: none;
      text-align: center;
      margin: 0px 20px 0px 20px;
      padding: 10px 0px;
      a {
        color: black;
        margin: 0 0px;
        font-weight: 900;
      }
    }
  }
}
.loginMainWrapper{
  &.guest-login {
    height: 100vh;
    margin-top: 0px;
    .loginLeftBlock {
      video{
        top: 0;
        height: 100%;
      }
    }
    .loginDetail{
      padding: 0px 0px 15px;
    }
  }
}
.loginMainWrapper{
  &.forget-vh {
    height: 100vh;
    margin-top: 0px;
    .loginLeftBlock {
      video{
        top: 0;
        height: 100%;
      }
    }
    .LoginRightBlock{
      .loginDetail{
        .loginBox{
          padding: 0px 10px;
        }
      }
      
    }
  }
  .inputBlock .inputWrapper input{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .inputBlock label{
    font-weight: 900;
  }
}
.loginMainWrapper {
  display: flex;
  // height: 100vh;
  align-items: flex-end;
  &.registerLogWrapper {
    padding-right: 0;
    
  }
  &.register-vh{
    height: 100vh;
    margin-top: 0px;
    .loginLeftBlock {
      video{
        top: 0;
        height: 100%;
      }
    }
  }
  .loginLeftWrapper {
    display: grid;
    height: 100%;
  }
  .loginLeftBlock {
    width: 65%;
    position: relative;
    video, .poster-img img {
      width: 100%;
      height: 90%;
      top: 50px;
      position: absolute;
      object-fit: cover;
    }
    .loginLeftBlockInner {
      position: relative;
      background-position: top;
      height: calc(100vh) !important;
      .main-box-img {
        width: 100%;
        align-items: center;
        position: absolute;
        justify-content: center;
        bottom: 5%;
        .video-width-img {
          width: 80%;
          height: 30%;
          object-fit: cover;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .loginLeftOverlay {
      position: absolute;
      // background: rgba(192,158,124 ,0.35);
      width: 100%;
      height: 100%;
      top: 0;
      border-radius: 10px;
      left: 0;
    }
    .loginLeftContent {
      position: relative;
      max-width: 70%;
      margin: auto;
      h2 {
        font-size: 50px;
        color: #fff;
        line-height: 70px;
        font-weight: 600;
      }
      button {
        padding: 15px 25px;
        font-size: 16px;
      }
    }
  }
  .LoginRightBlock {
    width: 30%;
    height: 95vh;
    display: flex;
    justify-content: center;
    background-color: #fff;
    .loginDetail {
      width: 100%;
      justify-content: space-evenly;
      -webkit-flex-direction: column;
      flex-direction: column;
      display: flex;
      overflow: auto;
      .downloadBlockWrapper {
        &.border-top{
          position: relative;
          padding: 30px 10px 0px 10px;
        }
      } 
      .border-top::before {
        content: "";
        width: 100%;
        height: 2px;
        background-image: url(../assets/svg/header-bg.svg);
        position: absolute; 
        margin-top: 10px;
        margin-bottom: 10px;
        left: 0;
        right: 0;
        top: 0;
    }
    

      @media (max-width: 1399px) {
        width: 100%;
        justify-content: initial;
        .loginHead{
          padding: 0px 0px !important;
          .loginLogo{
            img{
              width: 100%;
              height: 80px;
            }
          }
          .wel_msg {
            img {
              width: 50%;
              
          }
          }
        }
        .loginBox{
          .inputWrapper{
            input{
              padding: 5px 10px 5px 0px;
              // padding: 5px 10px;
            }
          }
          
        }
      }
      @media (max-width: 1299px) {
        .loginHead{
          .wel_msg{
            img{
              width: 40% ;
            }
          }
        }
        .loginBox {
          .from-group{
            margin-bottom: 15px ;
            label{
              font-size: 12px ;
            }
            .inputWrapper {
              input{
                font-size: 14px;
              }
            }
            .loginLinkBlock {
              margin: 15px 0 20px 0;
            }
            .loginButton{
              .commonButton,
              .commonOutline{
                padding: 3px 7px;
                font-size: 12px;
              }
            }
          }
          
        }
        .registerLogLink{
          margin-top: 10px !important;
          .border-top {
            padding: 20px 10px 0px 10px;
          }
        }
        
      }
      @media (max-width: 1060px) {
        .loginHead{
          .wel_msg{
            img{
              width: 35% !important;
            }
          }
        }
        .loginBox {
          input{
            font-size: 10px !important;
          }
          .loginLinkBlock {
            margin: 15px 0 15px 0;
          }
        }
        .downloadBlock {
          .downloadBlockItem-icon-apple {
            svg{
              width: 29px;
              height: 29px;
            }
          }
          .downloadBlockItem-icon-android {
            svg{
              width: 29px;
              height: 29px;
            }
          }
          .downloadBlockItem-icon-facebook {
            svg{
              width: 29px;
              height: 29px;
            }
          }
          .downloadBlockItem-icon-instagram {
            svg{
              width: 29px;
              height: 29px;
            }
          }
          .downloadBlockItem-icon-twitter {
            svg{
              width: 29px;
              height: 29px;
            }
          }
          .downloadBlockItem-icon-youtube {
            svg{
              width: 29px;
              height: 29px;
            }
          }
          .downloadBlockItem-icon-linkedin {
            svg{
              width: 29px;
              height: 29px;
            }
          }
        }
      }
      &.registerForm {
        width: 100%;
        padding: 0px 0px 15px;
        // height: 100vh;
        overflow: auto;
        .registerMainScroll {
          height: calc(100vh - 20px);
          overflow: auto;
        }
        .registrationSelect {
          flex-wrap: wrap;
          .registrationSelectItem {
            border: 1px solid #e1e1e1;
            background-color: #fafafa;
            padding: 8px 12px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            margin-right: 12px;
            margin-bottom: 5px;
            min-width: 160px;
            cursor: pointer;
            .registrationSelectImg {
              width: 40px;
              height: 40px;
              border: 1px solid #ccc;
              display: flex;
              margin-right: 10px;
              border-radius: 5px;
              background: #fff;
              img {
                margin: auto;
                width: 25px;
                height: 25px;
              }
            }
            span {
              font-weight: 600;
            }
            &.active {
              background-color: @themeLightColor;
              border: 1px solid @themeColor;
              .registrationSelectImg {
                border: 1px solid @themeColor;
              }
              span {
                color: #000;
              }
            }
          }
        }
        .loginHead {
          padding: 0 25px 0 25px;
          width: 100%;
        }
        .accountType {
          padding: 0 0 25px 25px;
        }
        .registerMainBlock {
          form {
            padding: 0 25px 0 25px;
            width: 100%;
            .registerFiledBlock {
              display: flex;
              margin-bottom: 30px;
              flex-wrap: wrap;
              .uploadeImageBlock {
                border-right: none;
                padding-right: 0;
                margin-right: 0;
                input {
                  width: 50px;
                  height: 50px;
                  right: 0;
                  cursor: pointer;
                }
                .uploadeBlock {
                  margin: 0 auto;
                  width: 50px;
                  height: 50px;
                  img {
                    width: 20px;
                    height: 20px;
                  }
                }
              }
              .from-group {
                width: calc(50% - 20px);
                margin-right: 20px;
              }
            }
            .infoTable {
              tr {
                th {
                  background: #f1f1f1;
                  border: 1px solid #f2f2f2;
                }
                td {
                  border: 1px solid #f2f2f2;
                }
              }
            }
          }
        }
      }
      .loginHead {
        // margin-bottom: 50px;
        padding: 0px 40px;
        margin-bottom: 15px;  
        text-align: center;
        h3 {
          font-size: 24px;
          line-height: 30px;
          font-weight: 600;
          margin-bottom: 10px;
          text-decoration: none;
        }
        p {
          max-width: 70%;
          font-size: 14px;
          color: #555;
          margin-top: 20px;
        }
        .wel_msg {
          // margin-bottom: 20px;
          img {
            width: 50%;
          }
        }
      }
      .loginBox {
        padding: 0px 40px;
        .from-group {
          margin-bottom: 25px;
        }
      }
      .loginLinkBlock {
        margin: 15px 0 30px 0;
      }
      .registerLogLink {
        text-align: center;
        margin-top: 20px;
        padding: 0px 40px;
        font-size: 12px;
        a {
          color: #000;
          font-weight: 600;
          margin-left: 5px;
          // text-decoration: underline;
          &:hover {
            // color: @themeColor;
            color: #b69865;
          }
        }
      }
      .rememberCheckBox {
        display: flex;
        align-items: center;
        .rememberLabel {
          margin-left: 10px;
          color: #000;
        }
      }
    }
  }
  .loginButton {
    display: flex;
    justify-content: space-between;
    .commonButton,
    .commonOutline {
      padding: 10px 20px;
      width: 49%;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 900;
    }
  }
}

.registerTitie {
  font-size: 18px;
  font-weight: 500;
}
ul.tabList {
  margin: 0;
  padding: 0;
  // position: absolute;
  li {
    list-style: none;
    a {
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 20px;
      display: flex;
      color: #000;
      cursor: pointer;
      span {
        width: 25px;
        height: 25px;
        border-radius: 100%;
        border: 1px solid #000;
        margin-right: 8px;
        display: block;
        text-align: center;
      }
      &.is-active {
        text-decoration: underline;
        font-weight: 600;
        span {
          background-color: #000;
          color: #fff;
          border: 1px solid #000;
          font-weight: normal;
        }
      }
    }
  }
}
p {
  color: #555;
  line-height: 24px;
  margin-bottom: 0 !important;
}
.kyc-block {
  width: 50%;
  .formSelect,
  .uploadeImageBlock {
    width: 100%;
    padding-right: 0;
    margin-right: auto;
    text-align: center;
    &.leftAlign {
      .title {
        text-align: center;
      }
      .uploadeBlock {
        margin-left: auto;
      }
    }
  }
  &:first-child {
    border-right: 1px solid #ddd;
    padding-right: 20px;
  }
  &:last-child {
    padding-left: 20px;
  }
}
@media (min-width: 768px) {
  .loginHead {
    .loginLogo {
      display: none;
    }
  }
}
@media (max-width: 767px) {
  .loginMainWrapper {
    .brandLogoContain {
      display: none !important;
    }
    .loginButton {
      justify-content: center;
      position: fixed;
      width: 100%;
      left: 0;
      padding: 10px;
      background: #fff;
      bottom: 0;
      box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.09);
    }
    .LoginRightBlock {
      max-width: 100% !important;
      width: 100%;
      .loginDetail {
        .loginLinkBlock {
          margin: 15px 0 0 0;
        }
        &.registerForm {
          .accountType {
            padding: 0;
            margin-bottom: 20px;
            .registrationSelect {
              display: -webkit-box;
              overflow: auto;
              flex-wrap: nowrap;
              &::-webkit-scrollbar {
                display: none;
              }
            }
          }
          .registerMainBlock {
            form {
              padding: 0;
              .registerFiledBlock {
                .from-group {
                  width: 100%;
                  margin: 0 0 10px 0;
                }
              }
              .ant-checkbox-wrapper {
                display: flex;
                .ant-checkbox {
                  margin-top: 5px;
                }
              }
            }
          }
        }
        .loginHead {
          .wel_msg {
            img{
              display: none;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .main-screen{
    background-color: #fff;
  }
  .loginBasicList{
    display: none;
  }
  .loginMainWrapper {
    flex-wrap: wrap;
    margin-top: 0px;
    .LoginRightBlock {
      max-width: 465px;
      margin: auto;
      // padding-bottom: 60px;
    }
    &.registerLogWrapper {
      .LoginRightBlock {
        max-width: 100%;
      }
    }
    &.registerLogWrapper {
      padding: 15px;
      .registerMainScroll {
        height: auto !important;
      }
    }
    
    .loginLeftBlock {
      display: none;
    }
    .LoginRightBlock {
      width: 100%;
      .loginDetail {
        width: 100%;
        // padding: 10px 20px;
        .loginHead {
          text-align: center;
          width: 100% !important;
          padding: 0 !important;
          margin-bottom: 30px;
          p {
            max-width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .loginMainWrapper {
    .LoginRightBlock {
      .loginDetail {
        .loginBox {
          .loginLinkBlock{
            a{
              font-size: 12px;
            }
            .rememberLabel{
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .loginBasicList {
    ul {
      li {
        a{
          font-size: 11px;
        }
        margin: 0px 12px 0px 12px;
      }
    }
  }
  .loginMainWrapper {
    .loginLeftBlock {
      .loginLeftBlockInner {
        background-position: center top 30%;
      }
      .loginLeftContent {
        h2 {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
    .LoginRightBlock {
      .loginDetail {
        &.registerForm {
          .registrationSelect {
            .registrationSelectItem {
              margin-right: 5px;
              min-width: auto;
            }
          }
        }
      }
    }
    .loginButton {
      .commonButton,
      .commonOutline {
        padding: 4px 7px;
        font-size: 10px;
      }
    }
  }
}
@media (max-width: 1299px) {
  .loginMainWrapper {
    
      .loginBasicList {
        ul {
          li {
            a {
              font-size: 12px;
              display: inline-block;
              text-align: center;
            }
          }
        }
      }
      .loginLeftBlock {
      .loginLeftContent {
        h2 {
          font-size: 40px;
          line-height: 50px;
        }
      }
    }
  }
}

@primary-color: #b69865;