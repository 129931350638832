@import "variable.less";

.compareMainPage {
  padding: 10px 0px 0px 0px;
  .compareTable {
    overflow: auto;
    height: calc(100% - 43px); // 43 height of heading
    width: calc(100% - 0px);
    table {
      width: auto;
      position: relative;
      border-spacing: 0;
      tr {
        td {
          border: 1px solid #e8e8e8;
          padding: 10px;
          text-align: left;
          max-width: 180px !important;
          width: 350px;
          min-width: 350px !important;
          max-width: 550px !important;
          &:first-child {
            width: 150px;
            min-width: 150px !important;
            max-width: 200px !important;
            letter-spacing: 0.5px;
            position: sticky !important;
            left: -1px;
            background-color: #f8f8f8;
            border: 1px solid #e8e8e8;
            color: #000;
            font-weight: 600;
            z-index: 1;
          }
          &.compareHead {
            position: relative;
            img {
              &.diamondCompareImg {
                width: 130px;
                height: 130px;
                object-fit: contain;
                margin: auto;
              }
            }
            .closeImage {
              position: absolute;
              right: 8px;
              top: 8px;
              width: 14px;
              height: 14px;
              img {
                width: 10px;
                height: 10px;
                display: block;
              }
            }
            .checkImageBox {
              position: absolute;
              left: 8px;
              top: 8px;
              .ant-checkbox-wrapper,
              .ant-checkbox {
                display: block;
              }
            }
          }
          &.compareImage {
            text-align: center;
          }
        }
        &:first-child {
          td {
            height: 154px;
            min-height: 154px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
.imageSliderFullBlock {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex !important;
  iframe {
    box-sizing: border-box;
    border: none;
    width: 93%; 
    height: 100%; 
    min-height: 315px;
  }
}
.compareBottomIcon {
  display: flex;
  margin-top: 10px;
  align-items: center;
  border: 1px solid #ddd;
  padding: 5px 10px;
  justify-content: space-between;
  img {
    width: 16px;
    height: 16px;
  }
  .Text {
    line-height: 24px;
    color: #000;
    font-weight: 600;
    font-size: 13px;
  }
}
@media (max-width: 1399px) {
  .compareMainPage {
    padding: 0px 0px 0px 0px;
    .compareTable {
      overflow: auto;
      height: calc(100% - 43px); // 43 height of heading
      width: calc(100% - 0px); // 43 height of heading
      table {
        tr {
          td {
            border: 1px solid #e8e8e8;
            padding: 10px;
            text-align: left;
            max-width: 180px !important;
            width: 370px;
            min-width: 350px !important;
            max-width: 550px !important;
            &:first-child {
              width: 150px;
              min-width: 150px !important;
              max-width: 200px !important;
              letter-spacing: 0.5px;
              position: sticky !important;
              left: -1px;
              background-color: #f8f8f8;
              border: 1px solid #e8e8e8;
              color: #000;
              font-weight: 600;
              z-index: 1;
            }
          }
        }
      }
    }
  }
  .imageSliderFullBlock {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex !important;
    iframe {
      box-sizing: border-box;
      border: none;
      width: 95%;
      height: 300px;
    }
  }
}
@media (max-width: 1200px) {
  .imageSliderFullBlock {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex !important;
    iframe {
      box-sizing: border-box;
      border: none;
      width: 95%;
      height: 300px;
    }
  }
}
@media (max-width: 1299px) {
  .compareMainPage {
    padding: 0px 0px 0px 0px;
    .compareTable {
      overflow: auto;
      height: calc(100% - 43px); // 43 height of heading
      width: calc(100% - 0px); // 43 height of heading
      table {
        tr {
          td {
            border: 1px solid #e8e8e8;
            padding: 10px;
            text-align: left;
            max-width: 180px !important;
            width: 370px;
            min-width: 350px !important;
            max-width: 550px !important;
            &:first-child {
              width: 150px;
              min-width: 150px !important;
              max-width: 200px !important;
              letter-spacing: 0.5px;
              position: sticky !important;
              left: -1px;
              background-color: #f8f8f8;
              border: 1px solid #e8e8e8;
              color: #000;
              font-weight: 600;
              z-index: 1;
            }
          }
        }
      }
    }
  }
  .imageSliderFullBlock {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex !important;
    iframe {
      box-sizing: border-box;
      border: none;
      width:90%;
      height: 300px;
    }
  }
}
@media (max-width: 768px) {
  .dnaDetailBlock {
    width: 100%;
    overflow-x: auto;
  }
  .diamondDetailRight {
    .diamondDetailMore {
      .diamondDetailMoreHalf{
        ul{
          width: 100%;
          li{
            padding: 0px;
          }
        }
      }
    }
  }
  .diamondDetailMainTab{
    .diamondDetailLeft{
      width: 63%;
      .diamondDetailInnerDetail{
        .customerMaintab{
          li{
            margin: 0px;
            margin-top: 5px;
            margin-bottom: 5px;
          }
        }
      }
    }
    .diamondDetailRight{
      width: 37%;
    }
  }
 
}
@media (max-width: 767px) {
  .compareMainPage {
    .compareTable {
      height: calc(100vh - 161px);
      table {
        tr {
          td {
            border: 1px solid #e8e8e8;
            padding: 10px;
            text-align: left;
            max-width: 180px !important;
            width: 370px;
            min-width: 350px !important;
            max-width: 550px !important;
            &.compareHead {
              img {
                &.diamondCompareImg {
                  width: 100px;
                  height: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
  .viewdiamond {
    &.viewdiamond-mobile{
     .iframe{
        height: 420px;
     } 
    }
    .videos {
      .img-div {
        height: auto;
        .box-shadow{
          height: 250px;
        }
      }
      .video-div{
        height: auto;

        .box-shadow{
          height: 250px;
        }
      }
    }
  }
  .dnaDetailBlock {
    width: 100%;
    overflow-x: auto;
  }
  .imageSliderFullBlock {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex !important;
    iframe {
      box-sizing: border-box;
      border: none;
      width: 90%; 
      height: 100%; 
      min-height: 241px;
    }
  }
}

@primary-color: #b69865;