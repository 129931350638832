@import "../../styles/variable.less";

.thankYouWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: @thankBg;
    .thankYouMainBox{
        background: #fff;
        padding: 50px;
        border-radius: 20px;
    }
    .thankYouInnerBox{
        max-width: 700px;
        margin: auto;
        text-align: center;
        background: @headerStripColor !important;
        padding: 10px;
        border-radius: 20px;
        box-shadow:1px 11px 20px 0px rgba(0 ,0 ,0 , 0.15);
        .thankYouImgBlock{
            margin-bottom: 50px;
            img{
                width: 150px;
                height: 150px;
            }
        }
        .thankYouPageContent{
            h2{
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 20px;
            }
            p{
                color: #454545;
                line-height: 24px;
                margin-bottom: 20px !important;
            }
            button{
                padding: 13px 24px;
            }
        }
    }
}
@primary-color: #b69865;