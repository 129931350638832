@import "../../../styles/variable.less";

.downloadBlock {
  .downloadBlockItem {
    cursor: pointer;
    // padding: 10px 5px;
    padding: 5px 5px;
    margin: 2px;
    border-radius: 5px;
    border: 1px solid #e1e1e1;
    background-color: #fafafa;
    img {
      width: 130px;
      height: auto;
      object-fit: contain;
    }
  }
  .downloadBlockItem-icon-facebook,
  .downloadBlockItem-icon-instagram,
  .downloadBlockItem-icon-twitter,
  .downloadBlockItem-icon-youtube,
  .downloadBlockItem-icon-linkedin,
  .downloadBlockItem-icon-apple,
  .downloadBlockItem-icon-android{
    padding: 5px 8px;
  }
  .downloadBlockItem-icon {
    cursor: pointer;
    // padding: 10px 5px;
    padding: 5px 5px;
    margin: 2px;
    border-radius: 5px;
    // border: 1px solid #e1e1e1;
    // background-color: #fafafa;
    svg {
      // width: 30px;
      // height: 30px;
      object-fit: contain;

      &:hover {
        fill: #000;
        // width: 30px;
        // height: 30px;
        object-fit: contain;
      }
    }
  }
  .downloadBlockItem-icon-android {
    cursor: pointer;
    // padding: 10px 5px;
    margin: 0px 0px;
    border-radius: 5px;
    // border: 1px solid #e1e1e1;
    // background-color: #fafafa;
    svg {
      // width: 30px;
      // height: 30px;
      object-fit: contain;
      fill: #4d4d4f;
      &:hover {
        fill: #37b71d;
        // width: 30px;
        // height: 30px;
        object-fit: contain;
        content: 'Andriod';
      }
    }
    .tooltip-hover-name {
      display: inline;
      position: relative;
    }

    .tooltip-hover-name:hover {
      color: #c00;
      font-weight: 500;
      text-decoration: none;
    }

    .tooltip-hover-name:hover:after {
      background: rgba(0, 0, 0, 0);
      border-radius: 0.5em;
      top: -44px;
      color: #9da5a8;
      content: attr(title);
      display: block;
      left: -16px;
      //   left: -15px;
      padding: 0.3em 1em;
      position: absolute;
      text-shadow: 0 0px 0 #000;
      white-space: nowrap;
      z-index: 98;
    }
  }
  .downloadBlockItem-icon-apple {
    cursor: pointer;
    // padding: 10px 5px;
    margin: 0px 0px;
    border-radius: 5px;
    // border: 1px solid #e1e1e1;
    // background-color: #fafafa;
    svg {
      // width: 30px;
      // height: 30px;
      object-fit: contain;
      fill: #4d4d4f;
      &:hover {
        fill: #9da5a8;
        // width: 30px;
        // height: 30px;
        object-fit: contain;
      }
    }
    .tooltip-hover-name {
      display: inline;
      position: relative;
    }

    .tooltip-hover-name:hover {
      color: #c00;
      font-weight: 500;
      text-decoration: none;
    }

    .tooltip-hover-name:hover:after {
      background: rgba(0, 0, 0, 0);
      border-radius: 0.5em;
      top: -44px;
      color: #9da5a8;
      content: attr(title);
      display: block;
      //   left: -15px;
      padding: 0.3em 0.5em;
      position: absolute;
      text-shadow: 0 0px 0 #000;
      white-space: nowrap;
      z-index: 98;
    }

    // .tooltip-hover-name:hover:before {
    //   border: solid;
    //   border-color: #111 transparent;
    //   border-color: rgba(0, 0, 0, .0) transparent;
    //   border-width: .4em .4em 0 .4em;
    //   top: 1em;
    //   content: "";
    //   display: block;
    //   left: 2em;
    //   position: absolute;
    //   z-index: 99;
    // }
  }
  .downloadBlockItem-icon-facebook {
    cursor: pointer;
    // padding: 10px 5px;
    margin: 0px 0px;
    border-radius: 5px;
    // border: 1px solid #e1e1e1;
    // background-color: #fafafa;
    svg {
      // width: 30px;
      // height: 30px;
      object-fit: contain;
      fill: #4d4d4f;

      &:hover {
        fill: #3a5794;
        // width: 30px;
        // height: 30px;
        object-fit: contain;
      }
    }
    .tooltip-hover-name {
      display: inline;
      position: relative;
    }

    .tooltip-hover-name:hover {
      color: #c00;
      font-weight: 500;
      text-decoration: none;
    }

    .tooltip-hover-name:hover:after {
      background: rgba(0, 0, 0, 0);
      border-radius: 0.5em;
      top: -44px;
      color: #9da5a8;
      content: attr(title);
      display: block;
      left: -28px;
      //   left: -15px;
      padding: 0.3em 1em;
      position: absolute;
      text-shadow: 0 0px 0 #000;
      white-space: nowrap;
      z-index: 98;
    }
  }
  .downloadBlockItem-icon-instagram {
    cursor: pointer;
    // padding: 10px 5px;
    margin: 0px 0px;
    border-radius: 5px;
    // border: 1px solid #e1e1e1;
    // background-color: #fafafa;
    svg {
      // width: 30px;
      // height: 30px;
      object-fit: contain;
      fill: #4d4d4f;

      &:hover {
        // fill: #e95e3d;
        fill: url(#rg);
        // width: 30px;
        // height: 30px;
        object-fit: contain;
      }
    }
    .tooltip-hover-name {
      display: inline;
      position: relative;
    }

    .tooltip-hover-name:hover {
      color: #c00;
      font-weight: 500;
      text-decoration: none;
    }

    .tooltip-hover-name:hover:after {
      background: rgba(0, 0, 0, 0);
      border-radius: 0.5em;
      top: -44px;
      color: #9da5a8;
      content: attr(title);
      display: block;
      left: -28px;
      //   left: -15px;
      padding: 0.3em 1em;
      position: absolute;
      text-shadow: 0 0px 0 #000;
      white-space: nowrap;
      z-index: 98;
    }
  }
  .downloadBlockItem-icon-twitter {
    cursor: pointer;
    // padding: 10px 5px;
    margin: 0px 0px;
    border-radius: 5px;
    // border: 1px solid #e1e1e1;
    // background-color: #fafafa;
    svg {
      // width: 30px;
      // height: 30px;
      object-fit: contain;
      fill: #4d4d4f;

      &:hover {
        fill: #1b9cea;
        // width: 30px;
        // height: 30px;
        object-fit: contain;
      }
    }
    .tooltip-hover-name {
      display: inline;
      position: relative;
    }

    .tooltip-hover-name:hover {
      color: #c00;
      font-weight: 500;
      text-decoration: none;
    }

    .tooltip-hover-name:hover:after {
      background: rgba(0, 0, 0, 0);
      border-radius: 0.5em;
      top: -44px;
      color: #9da5a8;
      content: attr(title);
      display: block;
      left: -18px;
      //   left: -15px;
      padding: 0.3em 1em;
      position: absolute;
      text-shadow: 0 0px 0 #000;
      white-space: nowrap;
      z-index: 98;
    }
  }
  .downloadBlockItem-icon-youtube {
    cursor: pointer;
    // padding: 10px 5px;
    margin: 0px 0px;
    border-radius: 5px;
    // border: 1px solid #e1e1e1;
    // background-color: #fafafa;
    svg {
      // width: 30px;
      // height: 30px;
      object-fit: contain;
      fill: #4d4d4f;

      &:hover {
        fill: #f84334;
        // width: 30px;
        // height: 30px;
        object-fit: contain;
      }
    }
    .tooltip-hover-name {
      display: inline;
      position: relative;
    }

    .tooltip-hover-name:hover {
      color: #c00;
      font-weight: 500;
      text-decoration: none;
    }

    .tooltip-hover-name:hover:after {
      background: rgba(0, 0, 0, 0);
      border-radius: 0.5em;
      top: -44px;
      color: #9da5a8;
      content: attr(title);
      display: block;
      left: -25px;
      //   left: -15px;
      padding: 0.3em 1em;
      position: absolute;
      text-shadow: 0 0px 0 #000;
      white-space: nowrap;
      z-index: 98;
    }
  }
  .downloadBlockItem-icon-linkedin {
    cursor: pointer;
    margin: 0px 0px;
    border-radius: 5px;
    svg {
      object-fit: contain;
      fill: #4d4d4f;

      &:hover {
        fill: #0077b7;
        object-fit: contain;
      }
    }
    .tooltip-hover-name {
      display: inline;
      position: relative;
    }

    .tooltip-hover-name:hover {
      color: #c00;
      font-weight: 500;
      text-decoration: none;
    }

    .tooltip-hover-name:hover:after {
      background: rgba(0, 0, 0, 0);
      border-radius: 0.5em;
      top: -44px;
      color: #9da5a8;
      content: attr(title);
      display: block;
      left: -25px;
      padding: 0.3em 1em;
      position: absolute;
      text-shadow: 0 0px 0 #000;
      white-space: nowrap;
      z-index: 98;
    }
  }
}
.downloadBlockWrapper {
  .downloadBlockLabel {
    color: #000;
    margin-bottom: 10px;
    font-size: 14px;
    text-decoration: underline;
    font-weight: 600;
  }
}
.adma-login-main-content {
  .adma-app-login-content {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
}
.adma-app-login-content .downloadBlockWrapper {
  position: relative;
  padding-top: 30px;
}
.adma-app-login-content .downloadBlockWrapper::before {
  content: '';
  width: 100%;
  height: 2px;
  // background-image: url('http://fndemo.democ.in/static/media/header-bg.d043886c.svg');
  position: absolute;
  margin-bottom: 10px;
  margin-top: 15px;
  left: 0;
  right: 0;
  top: 0;
}

@media (max-width: 991px) {
  .downloadBlock {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .downloadBlock {
    .downloadBlockItem {
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}
.social-media-icon {
    justify-content: center;
    margin-bottom: 5px;
    width: 100%;
    .label-color {
        display: flex;
        align-items: center;
        width: 40%;
        margin-right: 10px;
        justify-content: flex-end;
      label {
        color: #4d4d4f;
        font-size: 12px;
        font-weight: 900;
        &:hover {
          color: #b69865;
        }
      }
    }
    .w-70 {
      width: 60%;
    }
  }

@primary-color: #b69865;